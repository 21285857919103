import React from "react";
import { Card, Avatar } from "antd";

const { Meta } = Card;

const GridCard = ({ sketch }) => {
  return (
    // <Card
    //   style={{
    //     width: "100%",
    //   }}
    //   cover={
    <img
      className="grid-item"
      style={{
        aspectRatio: "9 / 16",
        // objectFit: "contain",
        height: "12.61%%",
        objectPosition: "center",
        objectFit: "cover",
        width: "31.63%",
      }}
      alt="Sketch"
      src={sketch}
    />
    // }></Card>
  );
};
const GridCard2 = ({ sketch }) => {
  return (
    // <Card
    //   style={{
    //     width: "100%",
    //   }}
    //   cover={
    <img
      className="grid-item"
      style={{
        height: "50%",
        objectPosition: "center",
        objectFit: "cover",
        width: "48%",
      }}
      alt="Sketch"
      src={sketch}
    />
    // }></Card>
  );
};

export { GridCard, GridCard2 };
