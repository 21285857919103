import React from "react";
import { GridCard, GridCard2 } from "./GridCard";
import sketch1 from "../../src/assets/img/sketch1.JPG";
import sketch2 from "../../src/assets/img/sketch2.JPG";
import sketch3 from "../../src/assets/img/sketch3.JPG";
import sketch4 from "../../src/assets/img/sketch4.JPG";
import sketch5 from "../../src/assets/img/sketch5.JPG";
import sketch6 from "../../src/assets/img/sketch6.JPG";
import sketch7 from "../../src/assets/img/sketch7.JPG";
import sketch8 from "../../src/assets/img/sketch8.JPG";
import sketch9 from "../../src/assets/img/sketch9.JPG";
import sketch10 from "../../src/assets/img/sketch10.JPG";
import sketch11 from "../../src/assets/img/sketch11.JPG";
import sketch12 from "../../src/assets/img/sketch12.JPG";
import sketch13 from "../../src/assets/img/sketch13.JPG";
import sketch14 from "../../src/assets/img/sketch14.JPG";
import sketch15 from "../../src/assets/img/sketch15.JPG";
import sketchesImage from "../../src/assets/spot-light.png";

const images = [
  sketch1,
  sketch2,
  sketch3,
  sketch4,
  sketch5,
  sketch6,
  sketch7,
  sketch8,
  sketch9,
];

// const GridList = () => {
//   // Create an array of 28 items (4 rows * 7 columns)
//   const gridItems = Array(28)
//     .fill()
//     .map((_, index) => images[index % images.length]);

//   return (
//     <div className="flex flex-row gap-4">
//       <GridList2by2 />
//       <div className="grid-container gap-4">
//         {images.map((sketch, index) => (
//           <GridCard key={index} sketch={sketch} />
//         ))}
//       </div>
//     </div>
//   );
// };
const GridList = () => {
  return (
    <div className="grid-container">
      {images.slice(0, 9).map((sketch, index) => (
        <GridCard key={index} sketch={sketch} />
      ))}
    </div>
  );
};
const SpotLight = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        height: "100vh",
      }}>
      <div
        className="grid-container "
        style={{
          width: "27.22vw",
          height: "66.26vh",
        }}>
        {images.slice(0, 4).map((sketch, index) => (
          <GridCard2 key={index} sketch={sketch} />
        ))}
      </div>
    </div>
  );
};

const SketchImageSpotLight = () => {
  return (
    <div
      style={{
        padding: "10vh 0.256vw",
        display: "flex",
        alignItems: "flex-end",
        height: "100vh",
      }}>
      <img
        src={sketchesImage}
        alt="sketches"
        style={{
          width: "27.22vw",
          height: "66.26vh",
          objectFit: "cover",
        }}
      />
    </div>
  );
};
const SpotLightMobile = () => {
  return (
    <div
      style={{
        // padding: "10vh 0.256vw",

        width: "100vw",
        // alignItems: "flex-end",
        height: "100vh",
      }}>
      <img
        src={sketchesImage}
        alt="sketches"
        style={{
          aspectRatio: "9 / 16",
          width: "100vw",
          height: "100vh",
          objectFit: "contain",
          // width: "100%",
          // height: "100%",
        }}
      />
    </div>
  );
};

const SpotLightTablet = () => {
  return (
    <div
      style={{
        // padding: "10vh 0.256vw",

        width: "78.6vw",
        // alignItems: "flex-end",
        height: "78.6vh",
      }}>
      <img
        src={sketchesImage}
        alt="sketches"
        style={{
          aspectRatio: "9 / 16",
          width: "78.6vw",
          height: "78.6vh",
          objectFit: "contain",
          // width: "100%",
          // height: "100%",
        }}
      />
    </div>
  );
};

// const GridList = () => {
//   return (
//     <section className="h-screen overflow-y-scroll snap-y snap-mandatory">
//       {/* 2x2 Grid */}
//       <div className="grid-container grid grid-cols-2 gap-4 mb-4 h-screen snap-start">
//         {images.slice(0, 4).map((sketch, index) => (
//           <GridCard key={index} sketch={sketch} />
//         ))}
//       </div>

//       {/* 3x3 Grid */}
//       <div className="grid-container grid grid-cols-3 gap-4 h-screen snap-start">
//         {images.slice(4, 13).map((sketch, index) => (
//           <GridCard key={index} sketch={sketch} />
//         ))}
//       </div>
//     </section>
//   );
// };
export {
  GridList,
  SpotLight,
  SketchImageSpotLight,
  SpotLightMobile,
  SpotLightTablet,
};
