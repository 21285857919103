import React, { useState, useRef } from "react";
import vidTotal from "./assets/conv_videos/vidTotal.mp4";
const vidTotalMobile = vidTotal;
const BehindTheScenes = () => {
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <section style={styles.section}>
      <div style={styles.contentRow}>
        <video
          ref={videoRef}
          style={styles.backgroundVideo}
          preload="none"
          // autoPlay
          loop
          muted={isMuted}
          key={vidTotalMobile}>
          <source src={vidTotalMobile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div style={styles.controls}>
          <button onClick={togglePlay} style={styles.controlButton}>
            {isPlaying ? "Pause" : "Play"}
          </button>
          <button onClick={toggleMute} style={styles.controlButton}>
            {isMuted ? "Unmute" : "Mute"}
          </button>
        </div>
      </div>
    </section>
  );
};

const styles = {
  section: {
    position: "relative",
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    scrollSnapAlign: "start",
  },
  contentRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  backgroundVideo: {
    width: "auto",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "fill",
  },
  controls: {
    position: "absolute",
    bottom: "128px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    gap: "10px",
  },
  controlButton: {
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    color: "#333",
    transition: "background-color 0.3s ease",
  },
};

export default BehindTheScenes;
