import React from "react";
import "./App.css";
import Landing from "./Landing";
import OurMission from "./OurMission";
import PublicationsGallery from "./OurPublications";
import FashionFilms from "./FashionFilms";
import BehindTheScenes from "./BehindTheScenes";
import AboutUs from "./AboutUs";
import {
  GridList,
  SpotLight,
  SketchImageSpotLight,
} from "./SketchesGrid/GridList";
import MainSketches from "./OurSketches";
import FlowerPrint from "./FlowerPrint";

function App() {
  return (
    <div className="App">
      <main className="snap-container">
        <section className="snap-section">
          <Landing />
        </section>
        <section className="snap-section">
          <FlowerPrint />
        </section>
        <section className="snap-section">
          <OurMission />
        </section>
        <section className="snap-section-sketches">
          <MainSketches />
        </section>
        <section className="snap-section">
          <PublicationsGallery />
        </section>
        <section className="snap-section">
          <FashionFilms />
        </section>
        <section className="snap-section">
          <BehindTheScenes />
        </section>
        <section className="snap-section">
          <AboutUs />
        </section>
        {/* <section className="snap-section-sketches">
          <SketchImageSpotLight />
          <SpotLight />
          <GridList />
        </section> */}
      </main>
    </div>
  );
}

export default App;
