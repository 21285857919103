// src/OurMission.js
import React from "react";
import "./Section.css";
import missionImage from "./assets/mission.png"; // Import the image
const OurMission = () => {
  return (
    <section
      id="mission"
      className="section mission"
      style={{
        ...styles.section,
        backgroundImage: `url(${missionImage})`,
      }}>
      {/* <div style={styles.textContainer}> */}
      {/* <h1 style={styles.title}>Our Mission</h1> */}
      {/* <br></br>
        <p style={styles.paragraph}>
          The Numero I collection captures the essence of youthful energy and a
          vibrant spirit. With a playful and sexy edge, the collection embraces
          a legacy of fun and funkiness.
        </p>
        <p style={styles.paragraph}>
          It seamlessly blends daring elements with a touch of femininity and
          elegance, creating a unique and modern aesthetic. The pieces are
          adorned with vibrant colors and retro-inspired touches that give a nod
          to the past while maintaining a futuristic and contemporary feel.
        </p>{" "}
        <p style={styles.paragraph}>
          Whether it's a bold pattern, a statement silhouette, or an unexpected
          twist, the Numero I collection is designed to empower and inspire
          individuals who embrace their individuality and have a passion for
          bold fashion choices.
        </p> */}
      {/* </div> */}
    </section>
  );
};

export default OurMission;

const styles = {
  section: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100vw",
    height: "100vh",
    padding: "10vh 20px", // Added top padding to prevent title overflow
    boxSizing: "border-box",
    color: "#fff",
    textAlign: "center",
  },
  textContainer: {
    maxWidth: "800px",
    // height: "90%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.4)",
    overflowY: "auto", // Allow scrolling if content overflows
  },
  title: {
    fontSize: "2rem",
    marginBottom: "20px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  paragraph: {
    fontSize: "1rem",
    lineHeight: "1.6",
    fontWeight: "bold",
    marginBottom: "20px",
    textTransform: "uppercase",
  },
};
