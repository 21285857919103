import React from "react";
import missionImage from "./assets/mission.png"; // Import the image
import { useMediaQuery } from "react-responsive";

const AboutUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 762 });

  const styles = {
    section: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "100vw",
      height: "91vh",
      padding: "8px 8px",
      boxSizing: "border-box",
      color: "#fff",
      textAlign: "center",
    },
    textContainer: {
      maxWidth: "88.6%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      padding: "0px",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.4)",
      overflowY: "auto", // Allow scrolling if content overflows
    },
    title: {
      position: "sticky",
      // top: "0",
      backgroundColor: "rgba(0, 0, 0, 1)", // Optional: background color for better visibility
      zIndex: 1000,
      padding: "16px 0px", // Add padding for better spacing
      fontSize: "clamp(24px, 5vw, 30px)",
      marginBottom: "0px",
      letterSpacing: "0.3rem",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    paragraph: {
      fontSize: "clamp(14px, 2.5vw, 24px)",
      letterSpacing: "0.1rem",
      lineHeight: "1.4",
      // fontWeight: "bold",
      marginTop: "2xpx",
      marginBottom: "2px",
      textTransform: "uppercase",
    },
  };

  return (
    <section
      id="mission"
      style={{
        ...styles.section,
      }}>
      <div style={styles.textContainer}>
        <h1 style={styles.title}>About Augeo Puella Paris</h1>
        <br></br>
        <p style={styles.paragraph}>
          Augeo Puella, meaning “Grow Little Girl” in Latin, is a Parisian brand
          rooted in the belief that growth and constant evolution are the
          essence of true luxury.
        </p>
        <p style={styles.paragraph}>
          Our journey is defined by the meticulous crafting of ultra-luxurious,
          handmade garments that embody timeless elegance and artistic
          expression.
        </p>
        <p style={styles.paragraph}>
          Each garment is a unique limited edition masterpiece, featuring
          bespoke fabrics, handcrafted embroidery, exclusive vibrant prints by
          an acclaimed artist, and opulent 18k gold accents made by professional
          jewelers. Our commitment to unparalleled craftsmanship and innovation
          has positioned us at the forefront of high fashion, where tradition
          meets modernity.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
