// import React from "react";
// import { useMediaQuery } from "react-responsive";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import pbc1 from "../src/assets/publications/pbc1.jpg";
// import pbc2 from "../src/assets/publications/pbc2.jpg";
// import pbc3 from "../src/assets/publications/pbc3.jpg";

// const images = [pbc1, pbc2, pbc3];

// const PublicationsGallery = () => {
//   const isMobile = useMediaQuery({ maxWidth: 767 });

//   return (
//     <div style={styles.container}>
//       <Carousel
//         showThumbs={false}
//         showStatus={false}
//         infiniteLoop={true}
//         showArrows={true}
//         showIndicators={false}
//         stopOnHover={false}
//         swipeable={true}
//         interval={2000}
//         autoPlay={false}
//         renderArrowPrev={(onClickHandler, hasPrev, label) =>
//           hasPrev && (
//             <button
//               type="button"
//               onClick={onClickHandler}
//               title={label}
//               style={{ ...styles.arrow, left: 15 }}>
//               ‹
//             </button>
//           )
//         }
//         renderArrowNext={(onClickHandler, hasNext, label) =>
//           hasNext && (
//             <button
//               type="button"
//               onClick={onClickHandler}
//               title={label}
//               style={{ ...styles.arrow, right: 15 }}>
//               ›
//             </button>
//           )
//         }>
//         {images.map((src, index) => (
//           <div key={index} style={styles.slide}>
//             <img src={src} alt={`Slide ${index + 1}`} style={styles.image} />
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     width: "100vw",
//     height: "100vh",
//     padding: "32px", // Padding around the entire component
//     // margin: "32px", // Margin around the entire component
//     boxSizing: "border-box",
//     // backgroundColor: "#f0f0f0", // Background color for the main website component
//     scrollSnapAlign: "start", // Optional if you still want snap behavior
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     overflow: "hidden",
//   },
//   slide: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100%",
//     width: "100%",
//   },
//   image: {
//     width: "88%",
//     height: "auto", // Maintain aspect ratio
//     objectFit: "fill",
//     borderRadius: "10px", // Optional: Add some rounding to the images
//   },
//   arrow: {
//     position: "absolute",
//     top: "50%",
//     zIndex: 2,
//     transform: "translateY(-50%)",
//     backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background for arrows
//     color: "white",
//     border: "none",
//     borderRadius: "50%",
//     width: "40px",
//     height: "40px",
//     cursor: "pointer",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: "20px",
//   },
// };

// export default PublicationsGallery;

// import React from "react";
// import { useMediaQuery } from "react-responsive";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import pbc1 from "../src/assets/publications/pbc1.jpg";
// import pbc2 from "../src/assets/publications/pbc2.jpg";
// import pbc3 from "../src/assets/publications/pbc3.jpg";

// const images = [pbc1, pbc2, pbc3];

// const PublicationsGallery = () => {
//   const isMobile = useMediaQuery({ maxWidth: 767 });

//   return (
//     <div style={styles.container}>
//       {isMobile ? (
//         <div style={styles.mobileContainer}>
//           {images.map((src, index) => (
//             <div key={index} style={styles.mobileImageContainer}>
//               <img src={src} alt={`Slide ${index + 1}`} style={styles.image} />
//             </div>
//           ))}
//         </div>
//       ) : (
//         <Carousel
//           showThumbs={false}
//           showStatus={false}
//           infiniteLoop={true}
//           showArrows={true}
//           showIndicators={false}
//           stopOnHover={false}
//           swipeable={true}
//           interval={2000}
//           autoPlay={false}
//           renderArrowPrev={(onClickHandler, hasPrev, label) =>
//             hasPrev && (
//               <button
//                 type="button"
//                 onClick={onClickHandler}
//                 title={label}
//                 style={{ ...styles.arrow, left: 15 }}>
//                 ‹
//               </button>
//             )
//           }
//           renderArrowNext={(onClickHandler, hasNext, label) =>
//             hasNext && (
//               <button
//                 type="button"
//                 onClick={onClickHandler}
//                 title={label}
//                 style={{ ...styles.arrow, right: 15 }}>
//                 ›
//               </button>
//             )
//           }>
//           {images.map((src, index) => (
//             <div key={index} style={styles.slide}>
//               <img src={src} alt={`Slide ${index + 1}`} style={styles.image} />
//             </div>
//           ))}
//         </Carousel>
//       )}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     width: "100vw",
//     height: "100vh",
//     padding: "32px",
//     boxSizing: "border-box",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     overflow: "hidden",
//   },
//   slide: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100%",
//     width: "100%",
//   },
//   image: {
//     width: "88%",
//     height: "auto",
//     objectFit: "fill",
//     borderRadius: "10px",
//   },
//   arrow: {
//     position: "absolute",
//     top: "50%",
//     zIndex: 2,
//     transform: "translateY(-50%)",
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     color: "white",
//     border: "none",
//     borderRadius: "50%",
//     width: "40px",
//     height: "40px",
//     cursor: "pointer",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: "20px",
//   },
//   mobileContainer: {
//     display: "flex",
//     flexDirection: "column", // Stack images vertically
//     alignItems: "center",
//     width: "100%", // Full width on mobile
//   },
//   mobileImageContainer: {
//     marginBottom: "20px", // Space between images
//     width: "100%", // Full width on mobile
//     display: "flex",
//     justifyContent: "center",
//   },
// };

// export default PublicationsGallery;

import React from "react";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import pbc1 from "../src/assets/publications/pbc1.jpg";
import pbc2 from "../src/assets/publications/pbc2.jpg";
import pbc3 from "../src/assets/publications/pbc3.jpg";
import ZoomableImage from "./ZoomableImage";
import { height } from "@fortawesome/free-brands-svg-icons/fa42Group";

const images = [pbc1, pbc2, pbc3];

const PublicationsGallery = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div style={styles.container}>
      {isMobile ? (
        <div style={styles.mobileContainer}>
          {images.map((src, index) => (
            <div key={index} style={styles.mobileImageContainer}>
              <ZoomableImage
                src={src}
                alt={`Slide ${index + 1}`}
                style={styles.mobileImage}
              />
            </div>
          ))}
        </div>
      ) : (
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          showArrows={true}
          showIndicators={false}
          stopOnHover={false}
          swipeable={true}
          interval={2000}
          autoPlay={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...styles.arrow, left: 15 }}>
                ‹
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...styles.arrow, right: 15 }}>
                ›
              </button>
            )
          }>
          {images.map((src, index) => (
            <div key={index} style={styles.slide}>
              <ZoomableImage
                src={src}
                alt={`Slide ${index + 1}`}
                style={styles.carouselImage}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: "100vw",
    height: "100vh",
    padding: "16px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  slide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  carouselImage: {
    maxWidth: "100%",
    maxHeight: "calc(92vh - 32px)", // Subtracting container padding
    objectFit: "contain",
    width: "auto",
    height: "100vh",
  },
  mobileContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    padding: "16px",
    boxSizing: "border-box",
  },
  mobileImageContainer: {
    width: "100%",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mobileImage: {
    width: "100%",
    height: "auto",
    maxHeight: "calc(100vh - 64px)", // Subtracting padding and margin
    objectFit: "contain",
  },
  arrow: {
    position: "absolute",
    top: "50%",
    zIndex: 2,
    transform: "translateY(-50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
  },
};

export default PublicationsGallery;
