import React, { useState, useRef } from "react";
import videoFile1 from "./assets/conv_videos/1-augeo-puella-co-founder-art-director-1080.mp4";
import videoFile2 from "./assets/conv_videos/5-augeo-puella-co-founder-art-director-1080.mp4";
import videoFile3 from "./assets/conv_videos/9-augeo-puella-co-founder-art-director-1080.mp4";
import thumb1 from "../src/assets/thumbnails/thumb1.png";
import thumb2 from "../src/assets/thumbnails/thumb2.png";
import thumb3 from "../src/assets/thumbnails/thumb3.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyLoad from "react-lazy-load";

const videos = [
  {
    src: videoFile1,
    thumbnail: thumb1,
  },
  {
    src: videoFile2,
    thumbnail: thumb2,
  },
  {
    src: videoFile3,
    thumbnail: thumb3,
  },
];

const FashionFilms = () => {
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(videos[0].src);
  const videoRef = useRef(null);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleThumbnailClick = (videoSrc) => {
    setCurrentVideo(videoSrc);
    setIsPlaying(true);
  };

  return (
    <section style={styles.section}>
      <div style={styles.thumbnailRow}>
        {videos.map((video, index) => (
          <LazyLoad key={index} height={100} offset={100}>
            <LazyLoadImage
              src={video.thumbnail}
              alt={`Thumbnail ${index + 1}`}
              style={styles.thumbnail}
              onClick={() => handleThumbnailClick(video.src)}
            />
          </LazyLoad>
        ))}
      </div>
      <div style={styles.contentRow}>
        <video
          ref={videoRef}
          style={styles.backgroundVideo}
          // autoPlay
          loop
          muted={isMuted}
          key={currentVideo}>
          <source src={currentVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div style={styles.controls}>
          <button onClick={togglePlay} style={styles.controlButton}>
            {isPlaying ? "Pause" : "Play"}
          </button>
          <button onClick={toggleMute} style={styles.controlButton}>
            {isMuted ? "Unmute" : "Mute"}
          </button>
        </div>
      </div>
    </section>
  );
};

const styles = {
  section: {
    position: "relative",
    height: "100vh",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    scrollSnapAlign: "start",
  },
  thumbnailRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    padding: "6px",
  },
  thumbnail: {
    width: "100px",
    height: "auto",
    margin: "0 10px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  contentRow: {
    display: "flex",
    alignItems: "center", // Center the video vertically
    justifyContent: "center", // Center the video horizontally
    flex: 1,
    width: "100%",
    height: "100%", // Full height for content row
    position: "relative",
  },
  backgroundVideo: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain", // Maintain aspect ratio, ensuring the video fits within the viewport
    position: "absolute", // Position the video absolutely within the content row
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Center the video exactly in the middle
  },
  controls: {
    position: "absolute",
    bottom: "128px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    gap: "10px",
  },
  controlButton: {
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    color: "#333",
    transition: "background-color 0.3s ease",
  },
};

export default FashionFilms;
