// src/OurSketches.js
import React, { useEffect, useState } from "react";
// import "./Section.css";
import sketchesImage from "./assets/spot-light.png"; // Import the image
import {
  GridList,
  SketchImageSpotLight,
  SpotLight,
  SpotLightMobile,
  SpotLightTablet,
} from "./SketchesGrid/GridList";
const OurSketches = () => {
  return (
    <section
      id="sketches"
      className="section sketches"
      style={{ backgroundImage: `url(${sketchesImage})` }}>
      {/* <div className="text-container"> */}
      {/* <h1 style={styles.title}>Our Sketches</h1> */}
      {/* </div> */}
    </section>
  );
};
const styles = {
  title: {
    fontSize: "2rem",
    // marginBottom: "20px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  paragraph: {
    fontSize: "1rem",
    lineHeight: "1.6",
    fontWeight: "bold",
    // marginBottom: "20px",
    textTransform: "uppercase",
  },
};
const MainSketches = () => {
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const checkDeviceType = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setDeviceType("mobile");
      } else if (width >= 768 && width < 1369) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    checkDeviceType();
    window.addEventListener("resize", checkDeviceType);

    return () => window.removeEventListener("resize", checkDeviceType);
  }, []);

  const renderContent = () => {
    switch (deviceType) {
      case "mobile":
        return <SpotLightMobile />;
      case "tablet":
        return <TabletLayout />;
      default:
        return (
          <>
            <SketchTitle />
            <SketchImageSpotLight />
            <SpotLight />
            <GridList />
          </>
        );
    }
  };

  return <>{renderContent()}</>;
};

const SketchTitle = () => (
  <div
    style={{
      position: "absolute",
      top: "10%",
      left: "15.06%",
      zIndex: 10,
    }}>
    <h1
      style={{
        fontSize: "calc(100vw / 32)",
        textTransform: "uppercase",
        textShadow: "2px 2px 4px rgba(255, 255, 255, 0.5)",
        fontWeight: "bold",
        letterSpacing: "1.2rem",
        color: "white",
        textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
      }}>
      Sketches
    </h1>
  </div>
);
const SketchTitleBlack = () => (
  <div
    style={{
      position: "absolute",
      top: "10%",
      left: "15.06%",
      zIndex: 10,
    }}>
    <h1
      style={{
        fontSize: "calc(100vw / 32)",
        textTransform: "uppercase",
        textShadow: "2px 2px 4px rgba(255, 255, 255, 0.5)",
        fontWeight: "bold",
        letterSpacing: "1.2rem",
        color: "black",
        textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
      }}>
      Sketches
    </h1>
  </div>
);

const TabletLayout = () => (
  <>
    {/* <SketchTitleBlack /> */}
    <SpotLightMobile />
    {/* <SpotLight /> */}
    {/* <GridList /> */}
  </>
);
export default MainSketches;
