import React, { useState, useRef } from "react";

const ZoomableImage = ({ src, alt, style }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const containerRef = useRef(null);

  const toggleZoom = (e) => {
    if (isZoomed) {
      setIsZoomed(false);
    } else {
      const container = containerRef.current;
      const rect = container.getBoundingClientRect();
      const x = (e.clientX - rect.left) / rect.width;
      const y = (e.clientY - rect.top) / rect.height;
      container.style.transformOrigin = `${x * 100}% ${y * 100}%`;
      setIsZoomed(true);
    }
  };

  //   return (
  //     <div style={styles.wrapper}>
  //       <div
  //         ref={containerRef}
  //         style={{
  //           ...styles.imageContainer,
  //           transform: isZoomed ? "scale(2)" : "scale(1)",
  //           cursor: isZoomed ? "zoom-out" : "zoom-in",
  //         }}
  //         onClick={toggleZoom}>
  //         <img src={src} alt={alt} style={styles.image} />
  //       </div>
  //     </div>
  //   );
  // };
  return (
    <div style={{ ...styles.wrapper, ...style }}>
      <div
        ref={containerRef}
        style={{
          ...styles.imageContainer,
          transform: isZoomed ? "scale(2)" : "scale(1)",
          cursor: isZoomed ? "zoom-out" : "zoom-in",
        }}
        onClick={toggleZoom}>
        <img src={src} alt={alt} style={styles.image} />
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    overflow: "hidden", // Change from "visible" to "hidden"
    display: "inline-block",
    maxWidth: "100%", // Add this line
    borderRadius: "10px", // Add this line
  },
  imageContainer: {
    display: "inline-block",
    transition: "transform 0.3s ease",
    maxWidth: "100%", // Add this line
  },
  image: {
    width: "100%", // Change from maxWidth to width
    height: "auto",
    objectFit: "contain", // Change from "scale-down" to "contain"
  },
};

export default ZoomableImage;
