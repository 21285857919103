import React from "react";
import "./Section.css";
import landingImage from "./assets/landing.png"; // Import the image
import smallLandingImage from "./assets/landing.png";
import ProgressiveImage from "react-progressive-image";
import { useMediaQuery } from "react-responsive";
const FlowerPrint = () => {
  const isMobile = useMediaQuery({ maxWidth: 762 });

  const styles = {
    section: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "100vw",
      height: "100vh",
      padding: "10vh 20px", // Added top padding to prevent title overflow
      boxSizing: "border-box",
      color: "#fff",
      textAlign: "center",
    },
    textContainer: {
      padding: "8px",
      borderRadius: "10px",
      overflowY: "auto", // Allow scrolling if content overflows
      display: "flex",
      flexDirection: "column", // Stack title and subtitle vertically
      alignItems: "center", // Center align both title and subtitle
    },
    title: {
      marginBottom: "0px",
      color: "#000",
      textShadow: "2px 2px 4px rgba(255, 255, 255, 0.5)",
      textTransform: "uppercase",
      letterSpacing: "0.3em", // Control the letter spacing
      fontSize: "clamp(32px, 5vw, 40px)", // Adjust this value to fit the screen width
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      letterSpacing: "0.11267em", // Increased letter spacing
    },
    subtitle: {
      fontSize: "2rem",
      letterSpacing: "0.4em", // Increased letter spacing
      marginTop: "0px",
      color: "#000",
      textTransform: "uppercase",
    },
  };

  return (
    <ProgressiveImage
      src={landingImage}
      placeholder={smallLandingImage} // You need to create a small version of your image
    >
      {(src) => (
        <section
          className="section landing"
          style={{
            ...styles.section,
            backgroundImage: `url(${src})`,
          }}>
          {/* <div style={styles.textContainer}>
            <h2 style={styles.title}>Augeo Puella</h2>
            <br></br>
            <h2 style={styles.subtitle}>Paris</h2>
          </div> */}
        </section>
      )}
    </ProgressiveImage>
  );
};

export default FlowerPrint;
